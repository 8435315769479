import moment from 'moment';

function formatDate(datetime,format){
    return moment(datetime).format(format);
}

function randomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1) ) + min;
}

function randomString(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
         result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export{
    formatDate,
    randomInt,
    randomString
}