<template>
  <b-card>
    <template #header>
      <h6 class="mb-0">Change Password</h6>
    </template>
    <b-card-text>
      <div class="content">
        <FormTemplate v-if="form" :form="form">
          <b-form-group class="label-for-xl" label="Password" label-for="inputPassword">
            <b-input-group>
              <b-form-input
                  type="text" id="inputPassword" class="form-control form-control-xl"
                  placeholder="set password"
                  v-model="form.data.password"
                  :state="form.states.password"
              />
              <b-input-group-append>
                <b-button variant="primary" @click="generatePassword()"><BIconLock/></b-button>
              </b-input-group-append>
              <b-form-invalid-feedback v-if="form.errors">{{ form.errors.password }}</b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>
          <template v-slot:button.save.text>Update Password</template>
        </FormTemplate>
      </div>
    </b-card-text>
  </b-card>
</template>

<script>
import {Form} from '@/common/utils/parnekt';
import FormTemplate from '@/common/components/Form/Form';
import {randomString} from '@/common/utils/common';
import {BIconLock} from 'bootstrap-vue';
export default {
  props: ['member'],
  components: {
      FormTemplate,
      BIconLock
  },
  data(){
      return {
          form: null
      };
  },
  methods: {
    generatePassword(){
      this.form.set('password', randomString(10));
    }
  },
  mounted(){
    this.$emit('set-member-sidebar',{active: "member.changePassword"});
    this.form = new Form({password: ""},{
        onSubmit: (form) => {
            return this.$api.put(`user/${this.member.get('id')}/password`, {
                data: {password: form.get('password')}
            })
        },
        onError: function(form, err){
            console.log(err);
        }
    });
  },
};
</script>
